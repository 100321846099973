module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Professional Driveway Paving, Interlocking, Asphalt Sealing Services Ottawa","short_name":"bulldogsealing","start_url":"/","description":"Looking for professional driveway paving Ottawa services? Your search\n\t\t\t\tends here! We bring years of expertise &amp; complete happiness to every project.","lang":"en","background_color":"#ffffff","theme_color":"#3D3E8B","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d14fa1ce8f95a08d772de985ead8692"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JWG186CHDL"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
